<template>
  <div class="advertismentWrapper offerManagement listingListWrapper">
    <b-row class="listMgntFirstRow">
      <b-col md="12">
          <b-row class="listFilterRow offerMgntRow">
             <b-col md="12" sm="12" class="my-1">
               <h3 class="card-title">Filters</h3>
            </b-col>
            <b-col md="3" sm="6" class="dateInput subscrDateData">
              <b-form-group label="Date" class="mb-0">
                <b-form-datepicker v-model="date" placeholder="Select Date" />
              </b-form-group>
            </b-col>
            <b-col md="3" sm="6" class="sortInput dateInput subscrDateData">
              <b-form-group label="Status">
                <b-form-select
                  :options="SubscriptionStatus"
                  v-model="SubStatus"
                  placeholder="Search"
                  style="text-transform:capitalize;"
                />
              </b-form-group>
            </b-col>
            <b-col md="2" sm="4" style="margin-top: 37px">
              <span
                @click="(date = ''), (SubStatus = 'All')"
                style="
                  cursor: pointer !important;
                  text-decoration: underline;
                  color: #17D1C6;
                  font-weight: 400;
                  margin-top: 10px;
                  line-height:21px;
                "
              >
                Clear Filter</span
              >
            </b-col>
          </b-row>
      
      </b-col>
      <b-col md="12" v-if="!showSpinner">
          <b-row class="tableRowTitle">
            <b-col md="8" sm="7" class="my-1"> </b-col>
            <b-col md="4" sm="5" class="my-1 myOfferSerachInput" v-if="filteredItems && filteredItems.length">
              <b-form-input placeholder="Search..." v-model="filter" />
            </b-col>

            <b-col cols="12" v-if="filteredItems && filteredItems.length">
              <b-table
                class="subscription_user"
                hover
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                :items="filteredItems"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template #cell(PropertyId)="data">
                  <div style="display: flex; align-items: center">
                    <a
                      @click="goToListingDetailPage(data.item)"
                      style="text-decoration: underline;"
                    >
                      {{ data.item.propertyId }}
                    </a>
                  </div>
                </template>

                <template #cell(ListingType)="data">
                  <div style="white-space: nowrap">
                    {{
                      data.item.listingType
                        .split("_")
                        .map((x) => x.charAt(0).toUpperCase() + x.substring(1))
                        .join(" ")
                    }}
                  </div>
                </template>
                <template #cell(Property)="data">
                  <div @click="callPropertyEmit(data.item)">
                    <router-link target="_blank" :to="{name: 'listing-management-detail', params: { id: data.item.id }}">
                      <div style="display: flex; align-items: center">
                        <img
                          v-if="data.item.photos && data.item.photos.length"
                          width="40px"
                          height="40px"
                          style="
                            object-fit: cover;
                            border-radius: 5px;
                            margin-right: 15px;
                            min-width: 40px;
                            cursor: pointer
                          "
                          :src="data.item.photos[0]"
                          alt="property"
                        />
                        <div>
                          <span
                            style="
                              white-space: normal;
                              display: block;
                              color: #17D1C6;
                              font-weight: 500;
                              cursor: pointer
                            "
                            >{{ data.item.PropertyLocation }}</span
                          >
                          <!-- <span class="askingPriceText">$ {{ data.item.askingPrice }}</span> -->
                        </div>
                      </div>
                    </router-link>
                  </div>
                </template>
                <!-- <template #cell(Action)="">
                  <div style="display: flex">
                    <img
                      src="@/assets/images/svg/eye.svg"
                      alt="empty_star"
                      style="margin-right: 10px"
                    />
                  </div>
                </template> -->
                <template #cell(ListingStatus)="data">
                    <div v-if="data.item.isArchived == false" style="display: flex; align-items: center;text-transform:capitalize" :class="[{[data.item.ListingStatus]:data.item.isArchived == false}]">
                      <span class="status-area">{{ data.item.ListingStatus }}</span>
                    </div>
                    <div v-else>
                      Archived
                    </div>
                </template>
               <template #cell(SubscriptionStatus)="data">
                  <!-- <div style="display: flex; align-items: center;text-transform:capitalize" :class="data.item.SubscriptionStatus">
                    <span class="status-area">{{ data.item.SubscriptionStatus }}</span>
                  </div> -->
                  <div  :class="data.item.SubscriptionStatus" style="position:relative;">
                   
                    <b-dropdown
                      :text="data.item.SubscriptionStatus"
                      :class="data.item.SubscriptionStatus"
                      :disabled="showSpinner || data.item.isArchived == true"
                    >
                      <b-dropdown-item
                        style="text-transform: capitalize !important"
                        v-for="datas in displayStatus(
                          getSubStatus,
                          data.item.SubscriptionStatus
                        )"
                        :key="datas.displayName"
                        @click="openModel(data.item,datas)"
                        class="boldactive"
                        
                      >
                        {{ datas.displayName }}
                      </b-dropdown-item>
                    </b-dropdown>
                    <span style="white-space: nowrap;bottom: -22px;" v-if="data.item.status !== 5" class="auto-renew-span">Auto-renews On {{data.item.AutoRenewDate}}</span>
                  </div>
                  <!-- <div style="display: flex; align-items: center;text-transform:capitalize" :class="data.item.SubscriptionStatus" v-else>
                    <span class="status-area">{{ data.item.SubscriptionStatus }}</span>
                  </div> -->
                </template>
              </b-table>
            </b-col>
            <b-col v-else cols="12" style="width: 100%; text-align: center">
              <p>No records found</p>
            </b-col>

            <b-col v-if="filteredItems && filteredItems.length" class="d-flex justify-content-between flex-wrap pt-0 paginationCol">
              <!-- page length -->
              <div
                class="d-flex align-items-center"
                style="white-space: nowrap"
              >
                <span class="showText">Showing 1 to</span>
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  size="sm"
                  inline
                  :options="pageOptions"
                  style="margin: 0px 10px"
                />
                <span class="entiresText">of {{ totalRows }} </span> <span class="entiresText">entries</span>
              </div>

              <!-- pagination -->
              <div>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  first-number
                  last-number
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mb-0"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </b-col>
          </b-row>
      </b-col>
      <b-col md="12" v-else class="d-flex justify-content-center">
        <b-spinner style="width: 50px; height: 50px" />
      </b-col>
    </b-row>
      <b-modal
      id="modal-changeStatus"
      ok-title="Yes"
      cancel-title="No"
      centered
      @ok="changeStatus"
      :no-close-on-backdrop="true"
      hide-header
    >
      <h3>Are you sure you want to update the status?</h3>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BDropdown,
  BDropdownItem,
  BSpinner,
} from "bootstrap-vue";
import CardStatisticProfitChart from "@/views/card/card-statistic/CardStatisticProfitChart.vue";
import Ripple from "vue-ripple-directive";
import { mapGetters, mapActions } from "vuex";
import {getCommaSeperatedNumber } from "@/utils/commonMethods";
import * as updateFirebase from "@/utils/FirebaseQueries/updateQueries/updateQueries.js";
import { dbCollections } from "@/utils/firebaseCollections";
import moment from "moment";
import axios from "axios";
export default {
  name: "listing-management",
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    BSpinner,
    CardStatisticProfitChart,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      showSpinner: false,

      perPage: 10,
      pageOptions: [10, 25, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },

      fields: [
        {
          key: "PropertyId",
          label: "Property ID",
        },
        {
          key: "Property",
          label: "Property",
        },
        {
          key: "ListingDate",
          label: "Listing Date",
        },
        {
          key: "SubsriptionType",
          label: "Subscription Type",
        },
        {
          key: "SubscriptionAmount",
          label: "Subscription Amount",
        },
        {
          key: "ListingStatus",
          label: "Listing Status",
        },
        {
          key: "SubscriptionStatus",
          label: "Subscription Status",
        },
      ],
      items: [],
      users: [],

      location: "",
      priceRange: null,
      date: null,
      status: null,
      SubStatus: "All",
      propertyType: [
        { text: "All", value: null },
        "Mental Or Detox",
        "Half-Way or Shared",
        "Free Or Public",
      ],
      priceRangeOptions: [{ text: "All", value: null }, 1, 2, 3, 4, 5, 6, 7, 8],
      updatedStatus: {},
      subStatusObject : {}
    };
  },

  created() {
    var self = this;
    self.showSpinner = true;
    self.propertyData(self.$route.params.id).then(() => {
      self.showSpinner = false;
    });
  },

  methods: {
    ...mapActions({ propertyData: "property/propertyData" }),
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    goToListingDetailPage(data) {
      var self = this;
      self.$root.$emit("listingDetailPage", `${data.PropertyLocation}`);
      self.$router.push({
        name: "listing-management-detail",
        params: { id: data.id, name: `${data.state},${data.country}` },
      });
    },
    callPropertyEmit(data){
      var self = this;
      self.$root.$emit("listingDetailPage", `${data.PropertyLocation}`);
    },
      displayStatus(array,status){
      let subStatusArray = [];
      if(array && array.length){
        array.forEach(data => {
          if(data.displayName != status){
            subStatusArray.push(data);
          }
        })
        console.log(subStatusArray,'subStatusArray');
        return subStatusArray;
      }else{
        return [];
      }
    },
    openModel(data,datas) {
      this.updatedStatus = data;
      this.subStatusObject = datas;
      this.$bvModal.show(`modal-changeStatus`);
    },
    changeStatus(){
      // debugger; // eslint-disable-line
      var self = this;
      self.showSpinner = true;
      let index = self.filteredItems.findIndex(items => {
        return items.id == self.updatedStatus.id
      })
      console.log('self.subStatusObject.key',self.subStatusObject.key);
      // self.subStatusObject.key = self.subStatusObject.key === 1 ? 2 : self.subStatusObject.key === 2 ? 4 : self.subStatusObject.key === 3 ? 5 : self.subStatusObject.key === 4 ? 3 : 3;
      console.log('self.subStatusObject.key ---- AFTER',self.subStatusObject.key);
      if(index != -1){
        self.filteredItems[index].SubKey = self.subStatusObject.key;
        self.filteredItems[index].SubscriptionStatus = self.subStatusObject.displayName;
        self.filteredItems[index].ListingStatus = self.statusGetterArray[4].displayName;
        console.log({subcriptionId :self.updatedStatus.subscription,statusKey:self.subStatusObject.key},'{subcriptionId :self.updatedStatus.Subscription,statusKey:self.subStatusObject.key}');
        if(self.updatedStatus.subscription && self.updatedStatus.subscription != ''){
          axios.post(process.env.VUE_APP_APIURL+'/api/v1/delete-subcription',{subcriptionId :self.updatedStatus.subscription,statusKey:self.subStatusObject.key})
          .then((res) => {
            if(res){
              let updateObject = {
                status:self.subStatusObject.key == 1 ? 2 : self.subStatusObject.key == 2 ? 4 : self.subStatusObject.key == 3 ? 5 : 3,
                subscriptionStatus:self.subStatusObject.key
              }
              updateFirebase.updateRootCollectionDataByDocId(dbCollections.PROPERTY,self.updatedStatus.id,updateObject,(res) => {
                if(res){
                  self.$toast.success('Subscription status updated successfully');
                  self.showSpinner = false;
                  self.$bvModal.hide(`modal-changeStatus`);
                }
              })
            }
          }).catch((error) => {
            console.log('ERROR in call subscription delete api',error);
            self.showSpinner = false;
          })
        }else{
          let updateObject = {
            status: self.subStatusObject.key == 1 ? 2 : self.subStatusObject.key == 2 ? 4 : self.subStatusObject.key == 3 ? 5 : 3,
            subscriptionStatus:self.subStatusObject.key
          }
          updateFirebase.updateRootCollectionDataByDocId(dbCollections.PROPERTY,self.updatedStatus.id,updateObject,(res) => {
            if(res){
              self.$toast.success('Subscription status updated successfully');
              self.showSpinner = false;
              self.$bvModal.hide(`modal-changeStatus`);
            }
          })
        }
      }

    }
  },

  computed: {
    ...mapGetters({
      getSubStatus: "admin/subScriptionStatus",
      getIdSpecificPropertyArray: "property/getProperty",
      statusGetterArray: "admin/statusGetter",
    }),
    filteredItems() {
      debugger; // eslint-disable-line
      var self = this;
      // self.showSpinner = true;
      let arrayOfPropertyData = [];
      if (self.getIdSpecificPropertyArray.length) {
        console.log(
          "self.getIdSpecificPropertyArray",
          self.getIdSpecificPropertyArray,
          self.date
        );
        let tmp = self.getIdSpecificPropertyArray;
        tmp.forEach((data) => {
          if(data.subscriptionStatus == 1 || data.subscriptionStatus == 3){
            if (data.listingType == "free_or_public") {
              arrayOfPropertyData.push({
                ...data,
                country: data.contractBCountry,
                state: data.contractBState,
                askingPrice: getCommaSeperatedNumber(data.contractDAskingPrice),
                photos: data.contractFUploadPhotos,
                ListingDate: data.createdAt
                  ? moment(data.createdAt.seconds * 1000).format("MM/DD/YYYY")
                  : "",
                ListingStatus:
                  self.statusGetterArray[data.status - 1].displayName,
                SubscriptionStatus:
                  self.getSubStatus[data.subscriptionStatus - 1].displayName,
                SubsriptionType:'Free Or Public',
                SubscriptionAmount:data.subscriptionAmountIndoller !== undefined ? "$" + data.subscriptionAmountIndoller :'',
                AutoRenewDate:data.autoRenewalDate ? moment(new Date(data.autoRenewalDate.seconds*1000)).format('MM/DD/YYYY') : 'N/A',
                SubKey:data.subscriptionStatus,                
                PropertyLocation: `${
                  data.contractBStreetNumber
                    ? data.contractBStreetNumber
                    : ""
                } ${
                  data.contractBStreetName
                    ? data.contractBStreetName + ","
                    : ""
                } ${
                  data.contractBUnitorLot
                    ? data.contractBUnitorLot + ", "
                    : ""
                }${data.contractBCity}, ${
                  data.contractBState
                }, ${data.contractBCountry}, ${
                  data.contractBZipCode
                }`,
              });
            } else if (data.listingType == "mental_or_detox") {
              arrayOfPropertyData.push({
                ...data,
                country: data.ownerBCountry,
                state: data.ownerBState,
                askingPrice: getCommaSeperatedNumber(data.ownerDAskingPrice),
                photos: data.ownerFUploadPhotos,
                ListingDate: data.createdAt
                  ? moment(data.createdAt.seconds * 1000).format("MM/DD/YYYY")
                  : "",
                ListingStatus:
                  self.statusGetterArray[data.status - 1].displayName,
                SubscriptionStatus:
                  self.getSubStatus[data.subscriptionStatus - 1].displayName,
                SubsriptionType:'Mental Or Detox',
                SubscriptionAmount:data.subscriptionAmountIndoller !== undefined ? "$" + data.subscriptionAmountIndoller :'',
                AutoRenewDate:data.autoRenewalDate ? moment(new Date(data.autoRenewalDate.seconds*1000)).format('MM/DD/YYYY') : 'N/A',
                SubKey:data.subscriptionStatus,  
                 PropertyLocation: `${
                  data.ownerBStreetNumber
                    ? data.ownerBStreetNumber
                    : ""
                } ${
                  data.ownerBStreetName
                    ? data.ownerBStreetName + ","
                    : ""
                } ${
                  data.ownerBUnitorLot
                    ? data.ownerBUnitorLot + ", "
                    : "" 
                }${data.ownerBCity}, ${
                  data.ownerBState
                }, ${data.ownerBCountry}, ${
                  data.ownerBZipCode
                }`, 
              });
            } else if (data.listingType == "half_way_or_shared" || data.listingType == 'mental_or_detox_facility' || data.listingType == 'free_or_public_shelter') {
              arrayOfPropertyData.push({
                ...data,
                country: data.leaseBCountry,
                state: data.leaseBState,
                askingPrice: getCommaSeperatedNumber(data.leaseDAskingPrice),
                photos: data.leaseFUploadPhotos,
                ListingDate: data.createdAt
                  ? moment(data.createdAt.seconds * 1000).format("MM/DD/YYYY")
                  : "",
                ListingStatus:
                  self.statusGetterArray[data.status - 1].displayName,
                SubscriptionStatus:
                  self.getSubStatus[data.subscriptionStatus - 1].displayName,
                SubsriptionType:data.listingType === 'half_way_or_shared'
                                ? 'Half-Way or Shared House'
                                : data.listingType === 'mental_or_detox_facility'
                                ? 'Mental or Detox Facility'
                                : data.listingType === 'free_or_public_shelter'
                                ? 'Free or Public Shelter'
                                : '' ,
                SubscriptionAmount:data.subscriptionAmountIndoller !== undefined ? "$" + data.subscriptionAmountIndoller :'',
                AutoRenewDate:data.autoRenewalDate ? moment(new Date(data.autoRenewalDate.seconds*1000)).format('MM/DD/YYYY') : 'N/A',
                SubKey:data.subscriptionStatus,  
                PropertyLocation: `${
                    data.leaseAStreetNumber
                      ? data.leaseAStreetNumber
                      : ""
                  } ${
                    data.leaseAStreetName
                      ? data.leaseAStreetName + ","
                      : ""
                  } ${
                    data.leaseAUnitorLot
                      ? data.leaseAUnitorLot + ","
                      : ""
                  } ${data.leaseBCity}, ${
                    data.leaseBState
                  }, ${data.leaseBCountry}, ${
                    data.leaseBZipCode
                  }`,
                  isArchived : data.isArchived ? data.isArchived :false
              });
            }
          }
        });
        console.log("arrayOfPropertyData", arrayOfPropertyData);
        if (self.date) {
          arrayOfPropertyData = arrayOfPropertyData.filter(
            (f) =>
              f.ListingDate === moment(new Date(self.date)).format("MM/DD/YYYY")
          );
        }
        if (self.SubStatus && self.SubStatus != "All") {
          arrayOfPropertyData = arrayOfPropertyData.filter(
            (f) => f.SubscriptionStatus === self.SubStatus
          );
        }
        self.totalRows = arrayOfPropertyData.length;
        return arrayOfPropertyData;
      }
    },
    SubscriptionStatus() {
      var self = this;
      let arr = [];
      if (self.getSubStatus.length) {
        arr.push("All");
        self.getSubStatus.forEach((data) => {
          arr.push(data.displayName);
        });
        return arr;
      } else {
        return [];
      }
    },
  },
};
</script>

<style>
</style>