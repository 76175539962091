<template>
  <div>
    <b-spinner
      style="width: 50px; height: 50px"
      class="spinner"
      v-if="showSpinner"
    />
    <b-row>
      <b-col md="12" class="listingMgntDetail">
        <b-card>
          <b-row class="userDetailWrapper listingMgntDetail">
            <b-col xl="1">
              <b-col xl="12" class="p-0">
                <div
                  class="user_img"
                  v-if="
                    userDetailObject.profilePicture &&
                    userDetailObject.profilePicture !== '' &&
                    userDetailObject.profilePicture !== null
                  "
                >
                  <b-img
                    :src="userDetailObject.profilePicture"
                    alt="User image"
                    style="width: 100px; height: 100px"
                    rounded
                  />
                </div>
                <div class="user_img" v-else>
                  <b-img
                    src="../../../assets/images/avatars/defaultUserProfile.jpg"
                    alt="User image"
                    style="width: 100px; height: 100px"
                    rounded
                  />
                </div>
              </b-col>
            </b-col>
            <b-col xl="11">
              <b-row>
                <b-col xl="3" lg="3" md="4">
                  <h5 class="bold">User ID</h5>
                  <p>
                    {{
                      userDetailObject.UserId ? userDetailObject.UserId : "N/A"
                    }}
                  </p>
                  <h5 class="bold">Status</h5>
                  <p>
                    <b-dropdown
                      :text="userDetailObject.Status"
                      :class="userDetailObject.Status"
                      :disabled="showSpinner"
                    >
                      <b-dropdown-item
                        style="text-transform: capitalize !important"
                        v-for="data in displayStatus(
                          userStatus,
                          userDetailObject.Key
                        )"
                        :key="data.value"
                        class="boldactive"
                        @click="openModel(data)"
                      >
                        {{ data.text }}
                      </b-dropdown-item>
                    </b-dropdown>
                  </p>
                  <!-- <h5 class="bold">User Type</h5>
                  <p style="text-transform: capitalize !important">
                    {{
                      userDetailObject.UserType
                        ? userDetailObject.UserType
                        : "N/A"
                    }}
                  </p> -->
                </b-col>
                <b-col xl="3" lg="3" md="4">
                  <h5 class="bold">Full Name</h5>
                  <p>
                  {{
                    userDetailObject.FullName
                      ? userDetailObject.FullName
                      : "N/A"
                  }}
                  </p>
                </b-col>
                <b-col xl="3" lg="3" md="4">
                  <h5 class="bold">Company Name</h5>
                  <p>
                  {{
                    userDetailObject.CompanyName
                      ? userDetailObject.CompanyName
                      : "N/A"
                  }}
                  </p>
                </b-col>
                <b-col xl="3" lg="3" md="4">
                  <h5 class="bold">Mobile Number</h5>
                  <p>
                    {{
                      userDetailObject.UserMobileNumber
                        ? userDetailObject.UserMobileNumber
                        : "N/A"
                    }}
                  </p>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
     <b-row class="listMgntRow">
      <b-col md="2" class="listingMgntTebLeftSide">
        <b-col md="12">
          <b-card>
            <b-row>
              <b-col
                md="12"
                sm="12"
                class="my-1"
                @click="activeList = 'Listing List'"
                :class="{ activeTab: activeList == 'Listing List' }"
              >
                <p style="cursor: pointer">Listing List</p>
              </b-col>
              <b-col
                md="12"
                sm="12"
                class="my-1"
                @click="activeList = 'Subscription Details'"
                :class="{ activeTab: activeList == 'Subscription Details' }"
              >
                <p style="cursor: pointer">Subscription Details</p>
              </b-col>
              <b-col
                md="12"
                sm="12"
                class="my-1"
                @click="activeList = 'Transaction History'"
                :class="{ activeTab: activeList == 'Transaction History' }"
              >
                <p style="cursor: pointer">Transaction History</p>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-col>
      <b-col md="10">
        <b-col md="12" v-if="activeList == 'Listing List'">
          <ListingList />
        </b-col>
        <b-col md="12" v-if="activeList == 'Subscription Details'">
          <SubscriptionDetail />
        </b-col>
        <b-col md="12" v-if="activeList == 'Transaction History'">
          <TransactionHistory />
        </b-col>
      </b-col>
    </b-row>
    <b-modal
      id="modal-changeStatus"
      ok-title="Yes"
      cancel-title="No"
      centered
      @ok="changeStatus"
      :no-close-on-backdrop="true"
      hide-header
    >
      <h3>Are you sure you want to update the status?</h3>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BDropdown,
  BDropdownItem,
  BSpinner,
  BImg,
} from "bootstrap-vue";
import CardStatisticProfitChart from "@/views/card/card-statistic/CardStatisticProfitChart.vue";
import Ripple from "vue-ripple-directive";
import { getCollectionData } from "@/utils/FirebaseQueries/getQueries";
import { dbCollections } from "@/utils/firebaseCollections";
import { mapGetters, mapActions } from "vuex";
import { loopFunction } from "@/utils/commonMethods";
import * as updateFirebase from "@/utils/FirebaseQueries/updateQueries/updateQueries.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ListingList from "@/views/extensions/acl/Listinglist";
import SubscriptionDetail from "@/views/extensions/acl/subscriptionDetail";
import TransactionHistory from "@/views/extensions/acl/transactionHistory";
import * as updateTypesense from "@/utils/TypeSenseQueries/updateQueries.js";
import axios from "axios";
export default {
  name: "listing-management",
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    BSpinner,
    BImg,
    CardStatisticProfitChart,
    ToastificationContent,
    ListingList,
    SubscriptionDetail,
    TransactionHistory,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      showSpinner: false,

      perPage: 10,
      pageOptions: [10, 25, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },

      fields: [
        {
          key: "UserId",
          label: "User ID",
        },
        {
          key: "FullName",
          label: "Full Name",
        },
        {
          key: "CompanyName",
          label: "Company Name",
        },
        {
          key: "UserEmail",
          label: "User Email",
        },
        {
          key: "UserMobileNumber",
          label: "User Mobile Number",
        },
        // {
        //   key: "UserType",
        //   label: "User Type",
        // },
        {
          key: "Status",
          label: "Status",
        },
        {
          key: "Action",
          label: "Action",
        },
      ],
      items: [],
      users: [],

      location: "",
      priceRange: null,
      userType: null,
      status: null,
      priceRangeOptions: [{ text: "All", value: null }, 1, 2, 3, 4, 5, 6, 7, 8],
      userDetailObject: {},
      activeList: "Listing List",
      updatedStatus: {},
    };
  },

  created() {
    var self = this;
    self.init();
    self.propertyData(self.$route.params.id);
      
  },

  methods: {
    ...mapActions({ getUserData: "user/userData",propertyData:"property/propertyData" }),
    init() {
      var self = this;
      self.userDetailObject = {};
      self.getUserData().then(() => {
        if (self.allUserData.length > 0) {
          var index = self.allUserData.findIndex((item) => {
            return item.id == self.$route.params.id;
          });
          if (index != -1) {
            self.userDetailObject = {
              id: self.allUserData[index].id,
              profilePicture: self.allUserData[index].profilePicture
                ? self.allUserData[index].profilePicture
                : "",
              UserId: self.generateUid(self.allUserData[index].id),
              FullName: `${self.allUserData[index].firstName} ${self.allUserData[index].lastName}`,
              CompanyName: self.allUserData[index].companyName,
              UserEmail: self.allUserData[index].email,
              UserMobileNumber:self.allUserData[index].phoneNumber,
              email:self.allUserData[index].email,
              // UserType: self
              //   .commaSeprateUserType(self.allUserData[index].checkThatAllApply)
              //   .join(","),
              Status: self.allUserData[index].status
                ? self.usersStatus[self.allUserData[index].status - 1]
                    .displayName
                : "-",
              Key: self.allUserData[index].status
                ? self.usersStatus[self.allUserData[index].status - 1].key
                : "-",
              isEmailVerified: self.allUserData[index].isEmailVerified,
            };
            self.status = self.allUserData[index].status;
            self.$root.$emit(
              "UserDetailName",
              `${self.allUserData[index].firstName} ${self.allUserData[index].lastName}`
            );
          }
        }
      });
    },
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    commaSeprateUserType(statusArray) {
      var self = this;
      self.checkAllApply.forEach((data) => {
        statusArray.forEach((arr, index) => {
          if (arr == data.key) {
            statusArray[index] = data.displayName;
          }
        });
      });
      return statusArray;
    },
    generateUid(input) {
      let output = '';
      try {
          for (var i = 0, len = input.length; i < len; i++) {
                output += input[i].charCodeAt(0)
          }
          return output.substr(-7);
      } catch (error) {
        console.log(error, "?");
      }
    },
    openModel(data) {
      this.updatedStatus = data;
      this.$bvModal.show(`modal-changeStatus`);
    },
    changeStatus() {
      // debugger; // eslint-disable-line
      var self = this;
      self.showSpinner = true;
      let setStatus = "";
      let data = self.updatedStatus;
      if (data.key == 3) {
        axios
          // .post("http://localhost:4000/api/v1/blockUser", {
            .post(process.env.VUE_APP_APIURL + "/api/v1/blockUser", {
            uid: self.userDetailObject.id,
            email:self.userDetailObject.email
          })
          .then((apiRes) => {
            if (apiRes.data.status) {
              self.userDetailObject.Status = data.text;
              let updateObject = {
                status: data.key,
              };
              try {
                updateFirebase.updateRootCollectionDataByDocId(
                  dbCollections.USERS,
                  self.$route.params.id,
                  updateObject,
                  (res) => {
                    if(res){
                      self.updatePropertyStatus();
                      self.$toast({
                        component: ToastificationContent,
                        position: "top-right",
                        props: {
                          title: "User status updated successfully",
                          variant: "success",
                          icon: "",
                        },
                      });
                      setTimeout(() => {
                        self.init();
                        self.showSpinner = false;
                      }, 2000);
                    }
                  }
                );
              } catch {
                console.log("ERROR in update status in user management");
                self.showSpinner = false;
              }
            } else {
              self.showSpinner = false;
              self.$toast.error("Something went to wrong");
            }
          })
          .catch((error) => {
            self.showSpinner = false;
            console.log("ERROR in block user update api call", error);
          });
      }
      if (data.key == 2) {
        axios
          .post(process.env.VUE_APP_APIURL + "/api/v1/unBlockUser", {
            uid: self.userDetailObject.id,
          })
          .then((apiRes) => {
            if (apiRes.data.status) {
              if (self.userDetailObject.isEmailVerified == true) {
                self.userDetailObject.Status = data.text;
                setStatus = data.key;
              } else {
                self.userDetailObject.Status = "pending";
                setStatus = 1;
              }
              let updateObject = {
                status: setStatus,
              };
              try {
                updateFirebase.updateRootCollectionDataByDocId(
                  dbCollections.USERS,
                  self.$route.params.id,
                  updateObject,
                  (res) => {
                    self.$toast({
                      component: ToastificationContent,
                      position: "top-right",
                      props: {
                        title: "User status updated successfully",
                        variant: "success",
                        icon: "",
                      },
                    });
                    setTimeout(() => {
                      self.init();
                      self.showSpinner = false;
                    }, 2000);
                  }
                );
              } catch {
                console.log("ERROR in update status in user management");
                self.showSpinner = false;
              }
            } else {
              self.showSpinner = false;
              self.$toast.error("Something went to wrong");
            }
          })
          .catch((error) => {
            console.log("ERROR in unblock user update api call", error);
            self.showSpinner = false;
          });
      }
    },
    displayStatus(statusArray, status) {
      let statusarr = [];
      if (status != "") {
        statusArray.forEach((data) => {
          if (status != 1) {
            if (data.key != status) {
              statusarr.push(data);
            }
          } else {
            if (data.key == 3) {
              statusarr.push(data);
            }
          }
        });
        return statusarr;
      } else {
        return statusArray;
      }
    },
    updatePropertyStatus(){
      var self = this;
      console.log(self.getPropertyArray,'self.getPropertyArray')
      if(self.getPropertyArray && self.getPropertyArray.length){
        self.getPropertyArray.forEach((data) => {
          if(data.status == 2 || data.status == 6){
            let updateObject = {
              status:3,
              updatedAt:new Date()
            }
            let typeSenceObject = {
              status: 3,
              updatedAt: parseInt(new Date().getTime() / 1000),
            };
            updateFirebase.updateRootCollectionDataByDocId(dbCollections.PROPERTY,data.id,updateObject,(res) =>{
              updateTypesense
                .updatePropertyCollection(data.id, typeSenceObject)
                .then(() => {
                  console.log("UPDATED IN TYPESENSE");
                })
                .catch((error) => {
                  console.error("ERROR IN UPDATED IN TYPESENSE", error);
              });
            })
          }
        })
      }
    }
  },

  computed: {
    ...mapGetters({
      getStatuses: "admin/statusGetter",
      allUserData: "user/allUserData",
      checkAllApply: "admin/checkThatAllApply",
      usersStatus: "admin/userStatus",
      getPropertyArray:"property/getProperty"
    }),
    filteredItems() {
      // debugger // eslint-disable-line
      var self = this;
      self.showSpinner = true;
      let arrayOfUserData = [];
      let tmp = self.allUserData;
      try {
        tmp.forEach((data) => {
          var obj = {
            id: data.id,
            UserId: self.generateUid(data.id),
            FullName: `${data.firstName} ${data.lastName}`,
            CompanyName: data.companyName,
            UserEmail: data.email,
            UserMobileNumber: data.phoneNumber,
            email:data.email,
            // UserType: self
            //   .commaSeprateUserType(data.checkThatAllApply)
            //   .join(","),
            Status: data.status
              ? self.usersStatus[data.status - 1].displayName
              : "-",
            Key: data.status ? self.usersStatus[data.status - 1].key : "-",
            isEmailVerified: self.allUserData[index].isEmailVerified,
            Action: "...",
          };
          arrayOfUserData.push(obj);
        });
        if (self.userType != null) {
          if (self.userType) {
            arrayOfUserData = arrayOfUserData.filter((f) =>
              f.UserType.toLowerCase().includes(self.userType.toLowerCase())
            );
          }
        }
        if (self.status != null) {
          if (self.status) {
            arrayOfUserData = arrayOfUserData.filter((f) =>
              f.Status.toLowerCase().includes(self.status.toLowerCase())
            );
          }
        }

        self.totalRows = arrayOfUserData.length;
        self.showSpinner = false;
        return arrayOfUserData;
      } catch {
        self.showSpinner = false;
        console.log("ERROR in get records of userData");
      }
    },
    filteredStatuses() {
      var self = this;
      if (self.getStatuses && self.getStatuses.length) {
        self.statusOptions = [
          { text: "All", value: null },
          ...self.getStatuses.map((x) => {
            return { text: x.displayName, value: x.key };
          }),
        ];
      }
      return self.getStatuses.map((x) => {
        return {
          label: x.displayName,
          key: x.key,
        };
      });
    },
    checkUserType() {
      var self = this;
      let arr = [];
      if (self.checkAllApply.length) {
        arr.push({ text: "All", value: null });
        self.checkAllApply.forEach((data) => {
          arr.push(data.displayName);
        });
        return arr;
      } else {
        return [];
      }
    },
    userStatus() {
      var self = this;
      let arr = [];
      if (self.usersStatus.length) {
        self.usersStatus.forEach((data) => {
          if (data.key != 1) {
            arr.push({
              text: data.displayName,
              value: data.displayName.toLowerCase(),
              key: data.key,
              variant: data.variant,
            });
          }
        });
        return arr;
      } else {
        return [];
      }
    },
  },
};
</script>

<style>
.activeTab {
  color: #17D1C6;
  font-weight: 600;
}
.btn {
  text-transform: capitalize !important;
}
.spinner {
  z-index: 999;
  position: absolute;
  width: 50px;
  height: 50px;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
}
</style>