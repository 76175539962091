<template>
  <div class="advertismentWrapper offerManagement listingListWrapper transcationHistoryWrapper">
    <b-row class="listMgntFirstRow">
      <b-col md="12" v-if="!showSpinner">
       
          <b-row class="listFilterRow">
            <b-col md="12" sm="12" class="my-1">
              <h3 class="card-title">Transaction History</h3>
            </b-col>
            </b-row>
            <b-row class="tableRowTitle">
            <b-col md="8" sm="7" class="my-1">
            </b-col>
            <b-col md="4" sm="5" class="my-1 myOfferSerachInput" v-if="filteredItems && filteredItems.length">
              <b-form-input placeholder="Search..." v-model="filter" />
            </b-col>

            <b-col cols="12" v-if="filteredItems && filteredItems.length">
              <b-table
                hover
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                :items="filteredItems"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template #cell(OrderType)="data">
                  <div style="white-space: nowrap">
                    {{
                      data.item.OrderType === 'half_way_or_shared'
                                ? 'Half-Way or Shared House'
                                : data.item.OrderType === 'mental_or_detox_facility'
                                ? 'Mental or Detox Facility'
                                : data.item.OrderType === 'free_or_public_shelter'
                                ? 'Free or Public Shelter'
                                : '' 
                    }}
                  </div>
                </template>
              </b-table>
            </b-col>
            <b-col v-else cols="12" style="width: 100%; text-align: center">
              <p>No records found</p>
            </b-col>
             <b-col class="d-flex justify-content-between flex-wrap pt-0 paginationCol" v-if="filteredItems && filteredItems.length">
              <!-- page length -->
              <div
                class="d-flex align-items-center"
                style="white-space: nowrap"
              >
                <span class="showText">Showing 1 to</span>
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  size="sm"
                  inline
                  :options="pageOptions"
                  style="margin: 0px 10px"
                />
                <span class="entiresText">of {{ filteredItems.length}} </span> <span class="entiresText">entries</span>
              </div>
              <!-- pagination -->
              <div>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="filteredItems.length"
                  :per-page="perPage"
                  first-number
                  last-number
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mb-0"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </b-col>
          </b-row>
       
      </b-col>
      <b-col md="12" v-else class="d-flex justify-content-center">
        <b-spinner style="width: 50px; height: 50px" />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BDropdown,
  BDropdownItem,
  BSpinner,
} from "bootstrap-vue";
import CardStatisticProfitChart from "@/views/card/card-statistic/CardStatisticProfitChart.vue";
import Ripple from "vue-ripple-directive";
import { mapGetters, mapActions } from "vuex";
import { getCommaSeperatedNumber } from "@/utils/commonMethods.js";
import moment from "moment";
export default {
  name: "listing-management",
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    BSpinner,
    CardStatisticProfitChart,
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      showSpinner: false,

      perPage: 10,
      pageOptions: [10, 25, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },

      fields: [
        {
          key: "TransactionId",
          label: "Transaction ID",
        },
        {
          key: "OrderType",
          label: "Order Type",
        },
        {
          key: "Date",
          label: "Date",
        },
        {
          key: "Amount",
          label: "Amount",
        },
        {
          key: "Remarks",
          label: "Remarks",
        },
      ],
      items: [],
      users: [],

      location: "",
      priceRange: null,
      propType: null,
      status: null,
      PropStatus: null,
      propertyType: [
        { text: "All", value: null },
        "Mental Or Detox",
        "Half-Way or Shared",
        "Free Or Public",
      ],
      priceRangeOptions: [{ text: "All", value: null }, 1, 2, 3, 4, 5, 6, 7, 8],
    };
  },

  created() {
    var self = this;
    self.showSpinner = true;
    self.transactions(self.$route.params.id).then(() => {
      self.showSpinner = false;
    });
  },

  methods: {
    ...mapActions({ transactions: "transactionHistory/transactions" }),
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    displayFullName(name){
      if(name == 'Mental Or Detox'){
        return 'Mental Or Detox';
      }else{
        return name;
      }
    }
  },

  computed: {
    ...mapGetters({
      getTransactionHistoryArray: "transactionHistory/getTransactionHistory",
    }),
    filteredItems() {
      // debugger // eslint-disable-line
      var self = this;
      self.showSpinner = true;
      let arrayOfTransactionHistory = [];
      //   console.log('getTransactionHistoryArray',self.getTransactionHistoryArray);
      let tmp = self.getTransactionHistoryArray;
      try {
        tmp.forEach((data) => {
          var obj = {
            TransactionId: data.id,
            OrderType: data.listingType,
            Date: data.createdAt
              ? moment(data.createdAt.seconds * 1000).format("MM/DD/YYYY")
              : "",
            Amount: "$" + getCommaSeperatedNumber(data.amountIndoller),
            Remarks: data.remark,
          };
          arrayOfTransactionHistory.push(obj);
        });
        self.showSpinner = false;
        return arrayOfTransactionHistory;
      } catch {
        self.showSpinner = false;
        console.log("ERROR in get transaction history");
      }
    },
  },
};
</script>

<style>
</style>