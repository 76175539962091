<template>
  <div class="advertismentWrapper offerManagement listingListWrapper">
    <b-row class="listMgntFirstRow">
      <b-col md="12">
       
          <b-row class="listFilterRow">
             <b-col md="12" sm="12" class="my-1">
               <h3 class="card-title">Filters</h3>
            </b-col>
            <b-col md="3" sm="6" class="dateInput">
              <b-form-group label="Property Type">
                <b-form-select
                  :options="propertyType"
                  v-model="propType"
                  placeholder="Search"
                />
              </b-form-group>
            </b-col>
            <b-col md="3" sm="6" class="sortInput dateInput">
              <b-form-group label="Status">
                <b-form-select
                  :options="PropertyStatus.filter((e)=>{return e !== 'pending'})"
                  v-model="PropStatus"
                  placeholder="Search"
                  style="text-transform:capitalize;"
                />
              </b-form-group>
            </b-col>
            <b-col md="3" sm="6" style="margin-top: 34px" class="clearListData">
              <span
                @click="(PropStatus = 'All'), (propType = 'All')"
                style="
                  cursor: pointer;
                  text-decoration: underline;
                  color: #17D1C6;
                  font-weight: 500;
                  margin-top: 10px;
                "
              >
                Clear Filter</span
              >
            </b-col>
          </b-row>
       
      </b-col>
      <b-col md="12" v-if="!showSpinner">
      
          <b-row class="tableRowTitle">
            <b-col md="8" sm="7" class="my-1">
            </b-col>
            <b-col md="4" sm="5" class="my-1 myOfferSerachInput" v-if="filteredItems && filteredItems.length">
              <b-form-input placeholder="Search..." v-model="filter" />
            </b-col>

            <b-col cols="12" v-if="filteredItems && filteredItems.length">
              <b-table
                hover
                responsive
                :per-page="perPage"
                :current-page="currentPage"
                :items="filteredItems"
                :fields="fields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
                :filter="filter"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
              >
                <template #cell(PropertyId)="data">
                  <div style="display: flex; align-items: center">
                    <img
                      v-if="!data.item.isFeatured"
                      src="@/assets/images/svg/star_empty.svg"
                      alt="empty_star"
                      style="margin-right: 10px;cursor:pointer;"
                      @click="StarFillup(data.item, true)"
                    />
                    <img
                      v-else
                      src="@/assets/images/svg/star_filled.svg"
                      alt="filled_star"
                      style="margin-right: 10px;cursor:pointer;"
                      @click="StarFillup(data.item, false)"
                    />
                    <a
                      :to="`property/${data.item.propertyId}`"
                      style="text-decoration: underline;"
                      @click="goToListingDetailPage(data.item)"
                    >
                      {{ data.item.propertyId }}
                    </a>
                  </div>
                </template>

                <template #cell(ListingType)="data">
                  <div style="white-space: nowrap">
                    {{
                      data.item.listType
                    }} 
                  </div>
                </template>
                <template #cell(Property)="data">
                  <div @click="callPropertyEmit(data.item)">
                    <router-link target="_blank" :to="{name: 'listing-management-detail', params: { id: data.item.id }}">
                      <div style="display: flex; align-items: center">
                        <img
                          v-if="data.item.photos && data.item.photos.length"
                          width="40px"
                          height="40px"
                          style="
                            object-fit: cover;
                            border-radius: 5px;
                            margin-right: 15px;
                            min-width: 40px;
                            cursor: pointer
                          "
                          :src="data.item.photos[0]"
                          alt="property"
                        />
                        <div>
                          <span
                            style="
                              white-space: normal;
                              display: block;
                              color: #17D1C6;
                              font-weight: 500;
                              cursor: pointer
                            "
                            
                            >{{ data.item.PropertyLocation }}</span
                          >
                          <!-- <span class="askingPriceText">$ {{ data.item.askingPrice }}</span> -->
                        </div>
                      </div>
                    </router-link>
                  </div>
                </template>
                <template #cell(Status)="data">
                  <div style="white-space: nowrap;text-transform:capitalize" :class="data.item.Status" v-if="data.item.isArchived == false">
                    <!-- <span class="status-area"> {{data.item.Status}}</span> -->
                    <b-dropdown
                      :text="propertyStatusChange[data.item.status-2].displayName"
                      :class="propertyStatusChange[data.item.status - 2].displayName"
                    >
                    <b-dropdown-item v-for="(statusP) in propertyStatusChange.filter((e)=>{return e.displayName !== 'pending'})" v-b-tooltip.hover="{ placement: 'left', title: getHoverText(statusP.key,statusP.displayName) }" :key="statusP.key" @click="openStatusModel(statusP.key, data.item)">{{statusP.displayName}}</b-dropdown-item>
                    </b-dropdown>
                  </div>
                  <div v-else>
                    Archived
                  </div>
                </template>
                <!-- <template #cell(propertyId)="data">
                  <div style="display: flex; align-items: center;">
                    <img v-if="Math.round(Math.random()) === 0" src="@/assets/images/svg/star_empty.svg" alt="empty_star"/>
                    <img v-else src="@/assets/images/svg/star_filled.svg" alt="filled_star"/>
                    <a :to="`property/${data.item.propertyId}`" style="text-decoration: underline; margin-left: 10px">
                      {{data.item.propertyId}}
                    </a>
                  </div>
                </template>
                <template #cell(property_type)="data">
                  <div style="white-space: nowrap">
                    {{data.item.listingType.split("_").map((x) => (x.charAt(0).toUpperCase() + x.substring(1))).join(" ")}}
                  </div>
                </template>
                <template #cell(property)="data">
                  <div style="display: flex; align-items: center">
                    <img v-if="data.item.photos && data.item.photos.length" width="40px" height="40px" style="object-fit: cover; border-radius: 5px; margin-right: 15px; min-width: 40px;" :src="data.item.photos[0]" alt="property"/>
                    <div>
                      <span style="white-space: nowrap; display: block; color: #17D1C6; font-weight: 500">{{data.item.country}}, {{data.item.state}}.</span>
                      <span>$ {{data.item.askingPrice}}</span>
                    </div>
                  </div>
                </template> -->

                <!-- <template #cell(UserId)="data">
                  <div style="white-space: nowrap" @click="goToDetailPage(data.item)">
                    {{data.item.UserId}}
                  </div>
                </template> -->

                <!-- <template #cell(listed_by)="data">
                  <div style="white-space: nowrap">
                    {{data.item.listedBy || "N/A"}}
                  </div>
                </template> -->

                <!-- <template #cell(Status)="data">
                  <div>
                    <b-dropdown
                      style="width: -webkit-fill-available; margin-bottom: 5px;"
                      id="dropdown-1"
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      :text="filteredStatuses.filter((x) => x.key === data.item.status)[0].label"
                      :variant="filteredStatuses.filter((x) => x.key === data.item.status)[0].variant || 'dark'"
                    >
                      <b-dropdown-item v-for="(data2, index) in filteredStatuses" :key="index" variant="data2.variant" @click.prevent="data.item.status = data2.key">{{data2.label}}</b-dropdown-item>
                    </b-dropdown>
                    <span style="white-space: nowrap;">Auto-renews On 10/20/2022</span>
                  </div>
                </template> -->

                <template #cell(Action)="data">
                  <div style="display: flex" class="actionImage">
                    <img
                      src="@/assets/images/svg/eye.svg"
                      alt="empty_star"
                      style="margin-right: 10px;cursor: pointer;"
                      @click="goToListingDetailPage(data.item)"
                    />
                    <img 
                      :title="'Archive Property'"
                      v-if="data.item.isArchived == false && data.item.status == 5"
                      src="@/assets/images/svg/archive_icon.svg"  
                      alt="Archive Property"
                      style="cursor:pointer;"
                      @click="selectedArchiveProperty = data.item,$bvModal.show(`modal-trashDoc`)"
                    />
                    <!-- <img src="@/assets/images/svg/delete.svg" alt="empty_star"/> -->
                  </div>
                </template>
              </b-table>
            </b-col>
            <b-col v-else cols="12" style="width: 100%; text-align: center">
              <p>No records found</p>
            </b-col>
            <b-col class="d-flex justify-content-between flex-wrap pt-0 paginationCol" v-if="filteredItems && filteredItems.length">
              <!-- page length -->
               <div
                class="d-flex align-items-center"
                style="white-space: nowrap"
              >
                <span class="showText">Showing 1 to</span>
                <b-form-select
                  id="perPageSelect"
                  v-model="perPage"
                  size="sm"
                  inline
                  :options="pageOptions"
                  style="margin: 0px 10px"
                />
                <span class="entiresText">of {{ totalRows }} </span> <span class="entiresText">entries</span>
              </div>

              <!-- pagination -->
              <div>
                <b-pagination
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  first-number
                  last-number
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mb-0"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </b-col>
          </b-row>
      
      </b-col>
      <b-col md="12" v-else class="d-flex justify-content-center">
        <b-spinner style="width: 50px; height: 50px" />
      </b-col>
    </b-row>
    <b-modal
      id="modal-property-status"
      ok-title="Yes"
      cancel-title="No"
      centered
      @ok="checkForModalOpen"
      @cancel="cancelStatus"
      :no-close-on-backdrop="true"
      hide-header
    >
      <h3>{{statusChangeModalText}}</h3>
    </b-modal>
    <b-modal
      id="modal-warning-status"
      ok-only
      centered
      hide-footer
    >
      <h3>You can't change property staus because there is no subscription for this property</h3>
    </b-modal>
    <b-modal
      id="modal-cancle-status"
      ok-title="Yes"
      cancel-title="No"
      centered
      @ok="changeStatus"
      @cancel="cancelChangeStatus"
      :no-close-on-backdrop="true"
      hide-header
    >
      <h3>Changing the status to CANCELLED OR SOLD OR DRAFT will require company contact with the user, to request the paid subscription again. Are you sure you want to change this property status?</h3>
    </b-modal>
    <b-modal
      id="modal-trashDoc"
      ok-title="Confirm"
      cancel-title="Cancel"
      centered
      @ok="updateArchiveStatus"
      @cancel="cancelTrashProp"
      :no-close-on-backdrop="true"
      hide-header
    >
      <h3>Are you sure you want to archive this property? You will not be able to revert it back once the property is archived.</h3>
    </b-modal>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCard,
  BTable,
  BAvatar,
  BBadge,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
  BDropdown,
  BDropdownItem,
  BSpinner,
  BModal
} from "bootstrap-vue";
import CardStatisticProfitChart from "@/views/card/card-statistic/CardStatisticProfitChart.vue";
import Ripple from "vue-ripple-directive";
import { dbCollections } from "@/utils/firebaseCollections";
import { mapGetters, mapActions, mapMutations } from "vuex";
import {getCommaSeperatedNumber } from "@/utils/commonMethods";
import * as updateFirebase from "@/utils/FirebaseQueries/updateQueries/updateQueries.js";
import * as updateTypesense from "@/utils/TypeSenseQueries/updateQueries.js";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import axios from "axios";
import firebase from "@/utils/firebaseInit.js"
const db = firebase.firestore()
export default {
  name: "listing-management",
  components: {
    BRow,
    BCol,
    BCard,
    BTable,
    BAvatar,
    BBadge,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BDropdown,
    BDropdownItem,
    BSpinner,
    CardStatisticProfitChart,
    ToastificationContent,
    BModal
  },
  directives: {
    Ripple,
  },

  data() {
    return {
      showSpinner: false,

      perPage: 10,
      pageOptions: [10, 25, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },

      fields: [
        {
          key: "PropertyId",
          label: "Property ID",
        },
        {
          key: "Property",
          label: "Property",
        },
        {
          key: "ListingType",
          label: "Listing Type",
        },
        {
          key: "offersCount",
          label: "Application Count",
        },
        {
          key: "Status",
          label: "Status",
        },
        {
          key: "Action",
          label: "Action",
        },
      ],
      items: [],
      users: [],

      location: "",
      priceRange: null,
      propType: "All",
      status: null,
      PropStatus: "All",
      propertyType: [
        "All",
        "Mental Or Detox Facility",
        "Half-Way or Shared House",
        "Free Or Public Shelter",
      ],
      priceRangeOptions: [{ text: "All", value: null }, 1, 2, 3, 4, 5, 6, 7, 8],
      statusChangeModalText: '',
      selectedProperty: {},
      isCanclleStatus: false,
      isSecondModal: false,
      selectedArchiveProperty: null
    };
  },

  created() {
    var self = this;
    self.showSpinner = true;
    self.getPropertyData(self.$route.params.id).then(() => {
      self.showSpinner = false;
    });
  },

  methods: {
    ...mapActions({ getPropertyData: "property/propertyData" }),
    ...mapMutations({
        updateAllPropertyArray:"property/updateAllPropertyArray",
        updatePropertyData:"property/updatePropertyData"
    }),
    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    getHoverText(key,statusType) {
      if(key == 2) {
        return '“Active ($)” is the only status in which you are being charged so people can find your open bed.';
      } else if(key == 3) {
        return '“Draft” means you need to move the listing to active and check out if you want to show your shelter location online. The draft is retained for 7 days.'
      } else if (key == 4) {
        return '“Full” means you are at capacity and the shelter location is showing externally in the Full area of Shelter-Registry.com at no charge.'
      } else if(key == 5) {
        return '“Cancelled” means the shelter location is no longer viable.'
      } else {
        return statusType
      }
    },
    StarFillup(data, value) {
      var self = this;
      //   self.showSpinner = true;
      let index = self.filteredItems.findIndex((item) => {
        return item.id == data.id
      }) 
      if(index != -1){
        self.filteredItems[index].isFeatured = value;
      }
      
      if (value == true) {
        self.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Property added in featured list",
            variant: "success",
            icon: "",
          },
        });
      } else {
        self.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: "Property remove from featured list",
            variant: "success",
            icon: "",
          },
        });
      }
      let updateObject = {
        isFeatured: value,
        updatedAt: new Date(),
      };
      let typeSenceObject = {
        isFeatured: value,
        updatedAt: parseInt(new Date().getTime() / 1000),
      };
      updateFirebase.updateRootCollectionDataByDocId(
        dbCollections.PROPERTY,
        data.id,
        updateObject,
        (res) => {
          self.getPropertyData();
          updateTypesense
            .updatePropertyCollection(data.id, typeSenceObject)
            .then(() => {
              console.log("UPDATED IN TYPESENSE");
            })
            .catch((error) => {
              console.error("ERROR IN UPDATED IN TYPESENSE", error);
            });
          //   self.showSpinner = false;
        }
      );
    },
    goToListingDetailPage(data) {
      var self = this;
      self.$root.$emit("listingDetailPage", `${data.PropertyLocation}`);
      self.$router.push({
        name: "listing-management-detail",
        params: { id: data.id, name: `${data.state},${data.country}` },
      });
    },
    callPropertyEmit(data){
      var self = this;
      self.$root.$emit("listingDetailPage", `${data.PropertyLocation}`);
    },
    displayFullName(name){
      if(name == 'Mental Or Detox'){
        return 'Mental Or Detox';
      }else{
        return name;
      }
    },
    openStatusModel(key, item){
      try {
        this.selectedProperty = {
          key: key,
          item: item
        }
        console.log("this.selectedProperty", this.selectedProperty)
        if(this.selectedProperty.key !== this.selectedProperty.item.status) {
          this.statusChangeModalText = 'Are you sure you want to change this property status?';
          // this.$bvModal.show(`modal-property-status`);
          if(this.selectedProperty.item.status == 2 || this.selectedProperty.item.status == 6) {
              if(this.selectedProperty.key === 2 || this.selectedProperty.key == 6) {
                  // this.statusChangeModalText = `Are you sure you want to change this property staus?`;
                  this.$bvModal.show(`modal-property-status`);
              }else if(this.selectedProperty.key === 3 || this.selectedProperty.key == 4) {
                  this.isCanclleStatus = true;
                  this.isSecondModal = true;
                  // this.statusChangeModalText = 'Are you sure you want to make this property status sold? It will cancle the subscription of the property';
                  this.$bvModal.show(`modal-property-status`);
              } else if(this.selectedProperty.key == 5) {
                  this.isCanclleStatus = true;
                  this.isSecondModal = true;
                  // this.statusChangeModalText = 'Are you sure you want to make this property status canclled? It will cancle the subscription of the property';
                  this.$bvModal.show(`modal-property-status`);
              }
          } 
          // else if(this.selectedProperty.item.status == 3) {
          //     if(this.selectedProperty.key === 2 || this.selectedProperty.key ===  6) {
          //         if(this.selectedProperty.item.subscriptionStatus !== 1){
          //           //warning can't change status
          //           this.$bvModal.show('modal-warning-status');
          //         } else {
          //             // this.statusChangeModalText = `Are you sure you want to change this property staus?`;
          //             this.$bvModal.show(`modal-property-status`);
          //         }
          //     }else if(this.selectedProperty.key == 4) {
          //          this.isSecondModal = true;
          //         if(this.selectedProperty.item.subscriptionStatus === 1){
          //             this.isCanclleStatus = true;
          //             // this.statusChangeModalText = 'Are you sure you want to change this property staus to sold? It will cancle the subscription of the property';
          //             this.$bvModal.show(`modal-property-status`);
          //         } else {
          //             // this.statusChangeModalText = `Are you sure you want to change this property staus?`;
          //             this.$bvModal.show(`modal-property-status`);
          //         }
          //     } else if(this.selectedProperty.key == 5) {
          //          this.isSecondModal = true;
          //         if(this.selectedProperty.item.subscriptionStatus === 1){
          //             this.isCanclleStatus = true;
          //             // this.statusChangeModalText = 'Are you sure you want to change this property staus to canclled? It will cancle the subscription of the property';
          //             this.$bvModal.show(`modal-property-status`);
          //         } else {
          //             // this.statusChangeModalText = `Are you sure you want to change this property staus?`;
          //             this.$bvModal.show(`modal-property-status`);
          //         }
          //     }
          // }  
          else if(this.selectedProperty.item.status == 5 || this.selectedProperty.item.status == 4 || this.selectedProperty.item.status == 3) {
                console.log(this.selectedProperty,'this.selectedProperty');
                if(this.selectedProperty.key === 2 || this.selectedProperty.key === 6) {
                   //warning can't change status
                   this.$bvModal.show('modal-warning-status');
                } else if(this.selectedProperty.key == 3) {
                    // this.statusChangeModalText = `Are you sure you want to change this property staus?`;
                    this.$bvModal.show(`modal-property-status`);
                } else if(this.selectedProperty.key == 4) { 
                    this.isSecondModal = true;
                    this.$bvModal.show(`modal-property-status`);
                } else if(this.selectedProperty.key == 5) {
                    this.isSecondModal = true;
                    this.$bvModal.show(`modal-property-status`);
                }
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    cancelStatus() {
      this.$bvModal.hide('modal-property-status');
      this.selectedProperty = {};
      this.statusChangeModalText = '';
      this.isCanclleStatus = false;
    },
    cancelChangeStatus(){
      this.isSecondModal = false;
      this.isCanclleStatus = false;
      this.selectedProperty = {};
      this.$bvModal.hide('modal-cancle-status');
    },
    checkForModalOpen() {
        if(this.isSecondModal) {
            this.$bvModal.show('modal-cancle-status');
        } else {
            this.changeStatus();
        }
    },
    changeStatus() {
      var self = this;
      self.showSpinner = true;
      if(self.isCanclleStatus) {
          self.cancleSubscripiton();
      } else {
          self.directStatusChange();
      }
    },
    
    cancleSubscripiton() {
        try {
            axios.post(process.env.VUE_APP_APIURL + '/api/v1/delete-subcription', {
                subcriptionId : this.selectedProperty.item.subscription,
                statusKey: this.selectedProperty.key
            })
            .then((deleteRes) =>{
                if(deleteRes.data.status) {
                  this.updateAllPropertyArray({
                    id: this.selectedProperty.item.id,
                    subscription: "",
                    subscriptionStatus: 3
                  });
                  this.updatePropertyData({
                    id: this.selectedProperty.item.id,
                    subscription: "",
                    subscriptionStatus: 3
                  })
                  this.directStatusChange();
                } else {
                  console.error(deleteRes.data);
                  this.showSpinner = false;
                }
            })
            .catch((error) => {
                console.error(error);
                this.showSpinner = false;
            })
        } catch (error) {
            console.error(error);
            this.showSpinner = false;
        }
    },
    directStatusChange() {
      try {
        console.log(this.selectedProperty,'this.selectedProperty');
          let updateObj = {
              status: this.selectedProperty.key,
              subscriptionStatus: this.selectedProperty.key == 3 ? 4 : this.selectedProperty.key == 4 ? 2 : 3,
              updatedAt: new Date()
          }
          if(this.selectedProperty.key === 4 || this.selectedProperty.key === 5 || this.selectedProperty.key === 3) {
            updateObj.removeDocTime = new Date();
          }
          db.collection(dbCollections.PROPERTY)
            .doc(this.selectedProperty.item.id)
            .update(updateObj)
            .then(() => {
                updateTypesense.updatePropertyCollection(this.selectedProperty.item.id, {status: parseInt(this.selectedProperty.key)}).then(() => {
                    console.log("UPDATED IN TYPESENSE");
                })
                .catch((error) =>{
                    console.error(error);
                    this.showSpinner = false;
                })
                this.showSpinner = false;
                this.updateAllPropertyArray({
                  id: this.selectedProperty.item.id,
                  status: this.selectedProperty.key
                });
                this.updatePropertyData({
                  id: this.selectedProperty.item.id,
                  status: this.selectedProperty.key
                });
                this.selectedProperty = {};
                this.statusChangeModalText = '';
                this.isCanclleStatus = false;
                if(this.isSecondModal) {
                  this.isSecondModal = false;
                }
                this.$bvModal.hide('modal-property-status');
                this.$toast.success("Status updated successfully");
            })
            .catch((error) =>{
              console.error(error);
              this.showSpinner = false;
            })
      } catch (error) {
          console.error(error);
          this.showSpinner = false;
      }
    },
    cancelTrashProp(){
        this.selectedArchiveProperty = null
        this.$bvModal.hide(`modal-trashDoc`);
    },
    updateArchiveStatus() {
      try {
        this.showSpinner = true;
        if(this.selectedArchiveProperty == null) {
          this.$toast.success("Something went wronge");
          return;
        }
        let statusObject = {
          isArchived: true
        }
        db.collection(dbCollections.PROPERTY).doc(this.selectedArchiveProperty.id).update(statusObject).then(()=>{
          updateTypesense.updatePropertyCollection(this.selectedArchiveProperty.id, statusObject).then(async() => {
            await this.updateAllPropertyArray({
              id: this.selectedArchiveProperty.id,
              isArchived: true
            });
            await this.updatePropertyData({
              id: this.selectedArchiveProperty.id,
              isArchived: true
            });
            this.cancelTrashProp()
            this.$toast.success("Property archived successfully");
            this.showSpinner = false;
          }).catch((error)=>{
            this.showSpinner = false;
            console.error(error)
          })
        }).catch((error)=>{
          this.showSpinner = false;
          console.error(error)
        })
      } catch (error) {
        console.error(error);
      }
    },
  },

  computed: {
    ...mapGetters({
      getStatuses: "admin/statusGetter",
      getPropertyArray: "property/getProperty",
    }),
    filteredItems() {
      // debugger // eslint-disable-line
      var self = this;
      self.showSpinner = true;
      //   console.log('getPropertyArray',self.getPropertyArray);
      let arrayOfPropertyData = [];
      let tmp = self.getPropertyArray;
      try {
        tmp.forEach((data) => {
          if (data.listingType === "free_or_public") {
            arrayOfPropertyData.push({
              ...data,
              country: data.contractBCountry,
              state: data.contractBState,
              askingPrice: getCommaSeperatedNumber(data.contractDAskingPrice),
              photos: data.contractFUploadPhotos,
              Status: data.status
                ? self.propertyStatusChange[data.status - 2].displayName
                : "",
              listType: "Free Or Public",
              PropertyLocation: `${
                data.contractBStreetNumber
                  ? data.contractBStreetNumber
                  : ""
              } ${
                data.contractBStreetName
                  ? data.contractBStreetName + ","
                  : ""
              } ${
                data.contractBUnitorLot
                  ? data.contractBUnitorLot + ", "
                  : ""
              }${data.contractBCity}, ${
                data.contractBState
              }, ${data.contractBCountry}, ${
                data.contractBZipCode
              }`,
            });
          } else if (data.listingType === "half_way_or_shared" || data.listingType == 'mental_or_detox_facility' || data.listingType == 'free_or_public_shelter') {
            if(data.status !== 1) {
              arrayOfPropertyData.push({
                ...data,
                country: data.leaseBCountry,
                state: data.leaseBState,
                askingPrice: getCommaSeperatedNumber(data.leaseDAskingPrice),
                photos: data.leaseFUploadPhotos,
                Status: data.status
                  ? self.propertyStatusChange[data.status - 2].displayName
                  : "",
                listType: data.listingType === 'half_way_or_shared'
                                  ? 'Half-Way or Shared House'
                                  : data.listingType === 'mental_or_detox_facility'
                                  ? 'Mental or Detox Facility'
                                  : data.listingType === 'free_or_public_shelter'
                                  ? 'Free or Public Shelter'
                                  : '' ,
                PropertyLocation: `${
                    data.leaseAStreetNumber
                      ? data.leaseAStreetNumber
                      : ""
                  } ${
                    data.leaseAStreetName
                      ? data.leaseAStreetName + ","
                      : ""
                  } ${
                    data.leaseAUnitorLot
                      ? data.leaseAUnitorLot + ","
                      : ""
                  } ${data.leaseBCity}, ${
                    data.leaseBState
                  }, ${data.leaseBCountry}, ${
                    data.leaseBZipCode
                  }`,
                  isArchived : data.isArchived ? data.isArchived :false
              });
            }
          } else if (data.listingType === "mental_or_detox") {
            arrayOfPropertyData.push({
              ...data,
              country: data.ownerBCountry,
              state: data.ownerBState,
              askingPrice: getCommaSeperatedNumber(data.ownerDAskingPrice),
              photos: data.ownerFUploadPhotos,
              Status: data.status
                ? self.propertyStatusChange[data.status - 2].displayName
                : "",
              listType: "Mental Or Detox",
              PropertyLocation: `${
                data.ownerBStreetNumber
                  ? data.ownerBStreetNumber
                  : ""
              } ${
                data.ownerBStreetName
                  ? data.ownerBStreetName + ","
                  : ""
              } ${
                data.ownerBUnitorLot
                  ? data.ownerBUnitorLot + ", "
                  : "" 
              }${data.ownerBCity}, ${
                data.ownerBState
              }, ${data.ownerBCountry}, ${
                data.ownerBZipCode
              }`, 
            });
          }
        });
        if (self.PropStatus != null && self.PropStatus != "All") {
          if (self.PropStatus) {
            arrayOfPropertyData = arrayOfPropertyData.filter((f) =>
              f.Status.toLowerCase().includes(self.PropStatus.toLowerCase())
            );
          }
        }
        if (self.propType != null && self.propType != "All") {
          if (self.propType) {
            arrayOfPropertyData = arrayOfPropertyData.filter((f) =>
              f.listType.toLowerCase().includes(self.propType.toLowerCase())
            );
          }
        }

        self.totalRows = arrayOfPropertyData.length;
        self.showSpinner = false;
        return arrayOfPropertyData;
      } catch (error){
        self.showSpinner = false;
        console.log("ERROR in get records of PropertyData",error);
      }
    },
    PropertyStatus() {
      var self = this;
      let arr = [];
      if (self.getStatuses.length) {
        arr.push("All");
        self.getStatuses.forEach((data) => {
          arr.push(data.displayName);
        });
        return arr;
      } else {
        return [];
      }
    },
    propertyStatusChange() {
      let status = [...this.getStatuses];
      let draftInd = status.findIndex((data) => {
        return data.displayName == "draft"
      })
      if (draftInd>-1) {
        status.splice(draftInd,1)
        return status
      } else {
        return status
      }
    },
  },
};
</script>

<style>
</style>